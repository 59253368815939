import {
  FolderOutlined,
  HighlightOutlined,
  CopyOutlined,
  ContainerOutlined,
  ImportOutlined,
  PlusCircleOutlined,
  VerticalAlignTopOutlined,
  VerticalAlignBottomOutlined,
  MinusCircleOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import HeaderWithIcon from "components/TeamRun/HeaderWithIcon";
import { commonStyle } from "lib/constant/AgGridCommonStyle";
import { comparatorSorter } from "lib/helpers/Pricer/Table";
import ColorIcon from "components/Pricer/ColorIcon/ColorIcon";
import { ACTION_BUTTONS_NAME } from "../GeneralActionButtons";
import { getDataFromLocalStorage } from "lib/helpers/GeneralFuncHelpers";
import ActionColumn from "components/TeamRun/ActionColumn";

// Constants for colors
export const DEFAULT_FONT_COLOR = "var(--color-white-1)";
export const ACTIVE_FONT_COLOR = "--color-black-1";
export const INACTIVE_FONT_COLOR = "grey";
export const DEFAULT_BACKGROUND_COLOR = "var(--color-white-1)";
export const UPDATE = "update";
export const COMPANY_ID = "company_id";
export const USER_ID = "user_id";
export const TRIGGER_ID = "trigger_id";
export const TEAMRUN_STATE = "teamRun_state";
export const TEAM_RUN_COULMN_STATE = "teamRun_columnState";
export const TEAM_RUN_DRAGEABLE_STATE = "teamRun_dragableState";
export const TEAM_RUN_SELECTED_ROWS = "teamRun_selectedRows";
export const TEAM_RUN_COLORS_STATE = "teamrun_colorState";

export const TEAM_RUN_COLUMNS = (headerName) => {
  return [
    {
      headerName: "",
      field: "",
      maxWidth: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    { headerName, field: "title", headerComponent: HeaderWithIcon },
    {
      headerName: "Bid Trader Company",
      field: "bid_trading_company",
      editable: false,
    },
    {
      headerName: "Ask Trader Company",
      field: "ask_trading_company",
      editable: false,
    },
    {
      headerName: "Actions",
      field: "actions",
      initialWidth: 130,
      minWidth: 130,
      cellRenderer: ActionColumn,
    },
    /// for sorting
    {
      headerName: "Commodity",
      field: "product",
      sortable: true,
      hide: true,
      sort: "asc",
      sortIndex: 1,
    },
    {
      headerName: "Contract",
      field: "contract",
      hide: true,
      sortable: true,
      sort: "asc",
      sortIndex: 2,
    },
    {
      headerName: "Tenor",
      sortIndex: 3,
      sort: "asc",
      field: "tenor",
      hide: true,
      sortable: true,
    },
    {
      headerName: "Option Type",
      sortIndex: 4,
      field: "type",
      sort: "asc",
      hide: true,
      sortable: true,
    },
    {
      headerName: "Strike",
      sortIndex: 5,
      sort: "asc",
      field: "strike",
      hide: true,
      sortable: true,
    },
    {
      headerName: "Is Active",
      sortIndex: 0,
      sort: "desc",
      field: "is_active",
      hide: true,
      sortable: true,
    },
  ].map((item) => {
    return {
      ...item,
      cellStyle: (params) => {
        const totalRows = params.api.getDisplayedRowCount();
        if (totalRows === 1) {
          return {
            fontSize: commonStyle.fontSize,
          };
        }

        const isLastRow = params.node?.rowIndex === totalRows - 1;
        if (isLastRow) {
          return {
            fontSize: commonStyle.fontSize,
          };
        }

        if (!params.data.same_active) {
          return {
            fontSize: commonStyle.fontSize,
            border: "none",
            borderBottom: "4px solid orange",
          };
        }

        return {
          fontSize: commonStyle.fontSize,
          border: "none",
          borderBottom:
            params.data.different_period && params.data.is_active
              ? "4px solid #d9d9d9"
              : null,
        };
      },
      comparator: comparatorSorter(item),
      suppressMenu: true,
    };
  });
};

const DYNAMIC_MENU_ITEMS = {
  [ACTION_BUTTONS_NAME.UN_HIGHLIGHT.key]: {
    key: ACTION_BUTTONS_NAME.UN_HIGHLIGHT.key,
    label: ACTION_BUTTONS_NAME.UN_HIGHLIGHT.label,
    icon: <HighlightOutlined />,
    index: 5,
  },
  [ACTION_BUTTONS_NAME.HIGHLIGHT.key]: {
    key: ACTION_BUTTONS_NAME.HIGHLIGHT.key,
    label: ACTION_BUTTONS_NAME.HIGHLIGHT.label,
    icon: <HighlightOutlined />,
    index: 5,
  },
  [ACTION_BUTTONS_NAME.MAKE_ACTIVE.key]: {
    key: ACTION_BUTTONS_NAME.MAKE_ACTIVE.key,
    label: ACTION_BUTTONS_NAME.MAKE_ACTIVE.label,
    icon: <PlusCircleOutlined />,
    index: 6,
  },
  [ACTION_BUTTONS_NAME.MAKE_IN_ACTIVE.key]: {
    key: ACTION_BUTTONS_NAME.MAKE_IN_ACTIVE.key,
    label: ACTION_BUTTONS_NAME.MAKE_IN_ACTIVE.label,
    icon: <MinusCircleOutlined />,
    index: 6,
  },
};

const colorArray = [] || getDataFromLocalStorage(TEAMRUN_STATE)?.[TEAM_RUN_COLORS_STATE] || [];

const colorDropDown = () => {
  if (colorArray && colorArray.length > 0) {
    let colorData = colorArray?.map((color) => {
      return {
        key: color.hex_code,
        label: color.label,
        icon: <ColorIcon color={color.hex_code} hex={true} />,
      };
    });
    let removeColor = [
      {
        key: ACTION_BUTTONS_NAME.REMOVE_COLOR.key,
        label: ACTION_BUTTONS_NAME.REMOVE_COLOR.label,
        icon: ACTION_BUTTONS_NAME.REMOVE_COLOR.icon,
      },
    ];
    return [...removeColor, ...colorData];
  }
  return [];
};

export const ACTION_COLUMN_MENU_ITEMS = [
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND.label,
    icon: <CopyOutlined />,
    index: 0,
  },
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.label,
    icon: <CopyOutlined />,
    index: 1,
  },
  {
    key: ACTION_BUTTONS_NAME.PULL_TO_PRICER.key,
    label: ACTION_BUTTONS_NAME.PULL_TO_PRICER.label,
    icon: <ImportOutlined />,
    index: 2,
  },
  {
    key: ACTION_BUTTONS_NAME.UPDATE_BID.key,
    label: ACTION_BUTTONS_NAME.UPDATE_BID.label,
    icon: <VerticalAlignTopOutlined />,
    index: 3,
  },
  {
    key: ACTION_BUTTONS_NAME.UPDATE_ASK.key,
    label: ACTION_BUTTONS_NAME.UPDATE_ASK.label,
    icon: <VerticalAlignBottomOutlined />,
    index: 4,
  },
  {
    key: ACTION_BUTTONS_NAME.ARCHIVE.key,
    label: ACTION_BUTTONS_NAME.ARCHIVE.label,
    icon: <FolderOutlined />,
    index: 7,
  },
  {
    key: ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.key,
    label: ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.label,
    icon: <AuditOutlined />,
    index: 8,
  },
  {
    key: ACTION_BUTTONS_NAME.TRADE.key,
    label: ACTION_BUTTONS_NAME.TRADE.label,
    icon: <ContainerOutlined />,
    index: 9,
  },

  {
    key: ACTION_BUTTONS_NAME.COLOR,
    label: ACTION_BUTTONS_NAME.COLOR.label,
    icon: ACTION_BUTTONS_NAME.COLOR.icon,
    index: 10,
    children: colorDropDown(),
  },
  {
    key: ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key,
    label: ACTION_BUTTONS_NAME.ADDITIONAL_DATA.label,
    icon: <ContainerOutlined />,
    index: 10,
  },
];

export const UPDATE_BID_AND_ASK_MODAL_INFO = {
  PREVIOUS_BID: { label: "Previous Bid", key: "previousBid" },
  NEW_BID: { label: "New Bid", key: "newBid" },
  NEW_ASK: { label: "New Ask", key: "newAsk" },
  PREVIOUS_ASK: { label: "Previous Ask", key: "previousAsk" },
  CANCEL: { label: "Cancel", key: "cancel" },
  FINISH: { label: "Finish", key: "finish" },
  REMOVE_BID: { label: "Remove Bid", key: "removeBid" },
  REMOVE_ASK: { label: "Remove Ask", key: "removeAsk" },
  QUANTITY: { label: "Quantity", key: "quantity" },
  PREV_QUANTITY: { label: "Previous Quantity", key: "previous_quantity" },
};

export const ADD_TRADE_MODAL_INFO = {
  ADD_TRADER: { label: "Add Trader", key: "addTrader" },
  CANCEL: { label: "Cancel", key: "cancel" },
  FINISH: { label: "Submit", key: "submit" },
}

const findAskIndex = ACTION_COLUMN_MENU_ITEMS.findIndex(
  (item) => item.key === ACTION_BUTTONS_NAME.UPDATE_ASK.key
);

function insertAt(array, index, ...elementsArray) {
  array.splice(index, 0, ...elementsArray);
}
const returnCloneWithOutRef = () => {
  return [...ACTION_COLUMN_MENU_ITEMS];
};

export const getAllMenuItemsPossibility = () => {
  const HIGH_LIGHT_ACTIVE = returnCloneWithOutRef();
  insertAt(
    HIGH_LIGHT_ACTIVE,
    findAskIndex,
    DYNAMIC_MENU_ITEMS[ACTION_BUTTONS_NAME.HIGHLIGHT.key],
    DYNAMIC_MENU_ITEMS[ACTION_BUTTONS_NAME.MAKE_ACTIVE.key]
  );

  const HIGH_LIGHT_IN_ACTIVE = returnCloneWithOutRef();
  insertAt(
    HIGH_LIGHT_IN_ACTIVE,
    findAskIndex,
    DYNAMIC_MENU_ITEMS[ACTION_BUTTONS_NAME.HIGHLIGHT.key],
    DYNAMIC_MENU_ITEMS[ACTION_BUTTONS_NAME.MAKE_IN_ACTIVE.key]
  );

  const UN_HIGH_LIGHT_ACTIVE = returnCloneWithOutRef();
  insertAt(
    UN_HIGH_LIGHT_ACTIVE,
    findAskIndex,
    DYNAMIC_MENU_ITEMS[ACTION_BUTTONS_NAME.UN_HIGHLIGHT.key],
    DYNAMIC_MENU_ITEMS[ACTION_BUTTONS_NAME.MAKE_ACTIVE.key]
  );

  const UN_HIGH_LIGHT_IN_ACTIVE = returnCloneWithOutRef();
  insertAt(
    UN_HIGH_LIGHT_IN_ACTIVE,
    findAskIndex,
    DYNAMIC_MENU_ITEMS[ACTION_BUTTONS_NAME.UN_HIGHLIGHT.key],
    DYNAMIC_MENU_ITEMS[ACTION_BUTTONS_NAME.MAKE_IN_ACTIVE.key]
  );

  return {
    HIGH_LIGHT_ACTIVE,
    HIGH_LIGHT_IN_ACTIVE,
    UN_HIGH_LIGHT_IN_ACTIVE,
    UN_HIGH_LIGHT_ACTIVE,
  };
};

export const teamRunTableHandler = {
  top: {
    cursor: "default",
  },
  bottom: { cursor: "default" },
  bottomLeft: { cursor: "default" },
  bottomRight: { cursor: "default" },
  topLeft: { cursor: "default" },
  topRight: { cursor: "default" },
  right: {
    width: "10px",
    height: "100%",
    backgroundColor: "var( --color-gray-5)",
    cursor: "col-resize",
  },
  left: {
    width: "10px",
    height: "100%",
    backgroundColor: "var( --color-gray-5)",
    cursor: "col-resize",
  },
};

export const marketDepthItem = {
  id: null,
  trading_company_id: null,
  trading_company: null,
  premium: {
    value: null,
    symbol: null,
    step_size: null,
  },
  old_premium: {
    value: null,
    symbol: null,
    step_size: null,
  },
  old_quantity: {
    value: null,
    symbol: null,
    step_size: null,
  },
  quantity: {
    value: null,
    symbol: null,
    step_size: null,
  },
};
