import { Button, Form, Input, Modal, Tooltip } from "antd";
import { AddTrader } from "api/TeamRun";
import CompanyTraders from "components/EditTradeDetails/CompanysTrader";
import { BACKEND_KEYS_NAME } from "lib/constant/EditTradeDetails/EditTradeDetails";
import { ADD_TRADE_MODAL_INFO } from "lib/constant/TeamRun/TeamRun";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { toast } from "lib/helpers/utilities";
import { useContext, useState } from "react";

const initialFormData = {
  name: "",
  email: "",
  trading_company_id: "",
};

function AddTraderForm({
  isOpen,
  setIsOpen = () => {},
  setTradingCompanyId,
  TradingCompanyId,
  setTraderId,
  onTraderChange,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const onFinish = async () => {
    setIsLoading(true);
    if (TradingCompanyId && TradingCompanyId === formData.trading_company_id) {
      setTradingCompanyId(null);
    }
    try {
      const response = await AddTrader(formData);
      if (response.data?.data?.message) {
        toast(response?.data?.message, "success");
      }
      setFormData(initialFormData);
      if (
        TradingCompanyId &&
        TradingCompanyId === formData.trading_company_id
      ) {
        setTradingCompanyId(formData.trading_company_id);
       setTimeout(() => {
          setTraderId(response?.data?.data?.id);
          onTraderChange(BACKEND_KEYS_NAME.TRADER, response?.data?.data?.name);
       } , 500)
      }
      setIsOpen(false);
    } catch (error) {
      console.log("error > ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const isAnyFieldEmpty =
    !formData.name || !formData.email || !formData.trading_company_id;


  return (
    <div className="">
      <Modal
        open={isOpen}
        width={"35%"}
        centered
        className={dark_Theme && "ant-modal-dark content_dark"}
        onCancel={hideModal}
        title={ADD_TRADE_MODAL_INFO.ADD_TRADER.label}
        bodyStyle={{ height: "250px", overflow: "auto" }}
        footer={[
          <Button disabled={isLoading} key="back" onClick={hideModal}>
            {ADD_TRADE_MODAL_INFO.CANCEL.label}
          </Button>,
          <Tooltip
            className="ms-2"
            title={isAnyFieldEmpty && "Please fill all the required fields"}
          >
            <Button
              onClick={onFinish}
              loading={isLoading}
              disabled={isAnyFieldEmpty || isLoading}
              key="submit"
              type="primary"
            >
              {ADD_TRADE_MODAL_INFO.FINISH.label}
            </Button>
          </Tooltip>,
        ]}
      >
        <Form
          name="basic"
          className="w-100 h-100 m-auto gap-3 d-flex flex-column justify-content-center align-items-center"
          autoComplete="off"
        >
          <div className="w-100 d-flex gap-3">
            <Input
              required
              disabled={isLoading}
              placeholder="Name"
              type="text"
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
              value={formData.name}
            />
            <Input
              required
              disabled={isLoading}
              // prefix={<img src="email.png" alt="email" width={20} height={20} />}
              type="email"
              placeholder="Email"
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
              value={formData.email}
            />
          </div>
          <CompanyTraders
            isPopup
            placeholder={"Trading Company"}
            isDisabled={isLoading}
            onChange={(e) => {
              setFormData({ ...formData, trading_company_id: e });
            }}
            value={formData.trading_company_id}
          />
        </Form>
      </Modal>
    </div>
  );
}

export default AddTraderForm;
